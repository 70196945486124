// eslint-disable-next-line no-unused-vars
const searchForm = ((window, $) => {
	const init = () => {
		// const searchFormContainer = $('.wc_ps_container');
		// let clickCounter = 0;
		const cartWrapper = document.querySelector('.cart-wrapper');
		// const searchButton = document.querySelector('.wc_ps_nav_submit_bt');
		const searchFormContainer = document.querySelector('.wc_ps_container');
		// const searchFormInput = document.querySelector('.wc_ps_search_keyword');
		// const searchFormSubmit = document.querySelector('.wc_ps_nav_submit_bt');

		// const navPrimary = document.querySelector('.nav-primary');
		cartWrapper.style.setProperty(
			`--cart-wrapper-width`,
			cartWrapper.offsetWidth * 1.5 + 'px'
		);

		// function doStuffOnButtonClick(e) {
		// 	const searchResultsWrapper = document.querySelector(
		// 		'.predictive_results'
		// 	);

		// 	cartWrapper.classList.toggle('show');
		// 	navPrimary.classList.toggle('nav-hidden');

		// 	if (cartWrapper.classList.contains('show')) {
		// 		searchFormInput.focus();
		// 	} else {
		// 		searchFormInput.blur();
		// 	}

		// 	if (
		// 		clickCounter > 0 &&
		// 		searchResultsWrapper !== null &&
		// 		searchFormInput.value !== '' &&
		// 		searchFormInput.value !== searchFormInput.dataset.psDefault_text
		// 	) {
		// 		const searchResultsPageArr = document.querySelector('.more_results a');
		// 		if ( searchResultsPageArr && searchResultsPageArr.length > 0 ) {
		// 			window.location.href = searchResultsPageArr[0].getAttribute('href');
		// 		}
		// 		// return false;
		// 	}
		// 	clickCounter++;
		// 	// e.preventDefault();
		// 	e.stopPropagation();
		// 	// e.stopImmediatePropagation();
		// 	return false;
		// }

		searchFormContainer.classList.add('wc_ps_container-is-active');
		// searchFormSubmit.addEventListener('click', doStuffOnButtonClick);

		jQuery('body').on('click', '.wc_ps_nav_right', function (evt) {
			jQuery('.cart-wrapper').toggleClass('show');
			jQuery('.nav-primary').toggleClass('nav-hidden');

			if (jQuery('.cart-wrapper').hasClass('show')) {
				jQuery('.wc_ps_search_keyword').focus();
			} else {
				jQuery('.wc_ps_search_keyword').blur();
			}

			if (validateSearchForm()) {
				jQuery(this).parents('form.wc_ps_form').trigger('submit');
			}

			evt.stopPropagation();
			return false;
		});

		// window.addEventListener('keydown', function (event) {
		// 	console.log('event console', !validateSearchForm());
		// 	console.log('event', event.key);
		// 	// const eventKey = event.key;
		// 	// return false;
		// 	if (event.key === 'Enter' && !validateSearchForm()) {
		// 		event.preventDefault();
		// 		return false;
		// 	}
		// });

		jQuery(document).on('keydown', function (evt) {
			if (evt.which === 13) {
				if (!validateSearchForm()) {
					evt.preventDefault();
					return false;
				}

				jQuery(this).parents('form.wc_ps_form').trigger('submit');
			}
		});

		jQuery('body').on('submit', '.wc_ps_form', function (evt) {
			if (!validateSearchForm()) {
				evt.preventDefault();
				return false;
			}
		});

		const validateSearchForm = () => {
			let searchInput = jQuery('.wc_ps_form .wc_ps_search_keyword');
			let searchInInput = jQuery('.wc_ps_form input[name="search_in"]');
			let searchOtherInput = jQuery(
				'.wc_ps_form input[name="search_other"]'
			);
			let searchValue =
				genesis_responsive_menu &&
				genesis_responsive_menu.woocommerceSearchBoxText
					? genesis_responsive_menu.woocommerceSearchBoxText
					: 'Suchen...';

			return (
				searchInput.val() &&
				searchInput.val() != searchValue &&
				searchInInput.val() &&
				searchOtherInput.val()
			);
		};
	};
	return {
		init,
	};
})(window, jQuery);

export default searchForm;
