/* eslint-disable @wordpress/dependency-group */
import $ from 'jquery';

import './inc/responsive-menus';
import stickyHeader from './inc/on-scroll';
import searchForm from './inc/search-form';
import primaryMenu from './inc/primary-menu';
import pricesStripCurrencySymbol from './inc/prices-strip-currency-symbol';
//
// console.log('theme.js');

$(window).on('load', function () {
	stickyHeader.init();
	// setTimeout(() => {
	// 	searchForm.init();
	// }, 1000);
	primaryMenu.init();

	// document
	// 	.querySelectorAll('.menu-primary > li.menu-item-has-children')
	// 	.forEach((el) => {
	// 		el.addEventListener('click', (event) => {
	// 			console.log({ event: event.target });
	// 			el.classList.toggle('has-grid-menu-open');
	// 			el.classList.toggle('menu-item-open');
	// 		});
	// 	});

	// document.querySelectorAll('.has-grid-menu').forEach((el) => {
	// 	el.addEventListener('mouseenter', () => {
	// 		document.body.classList.add('has-grid-menu-open');
	// 	});
	// 	el.addEventListener('mouseleave', () => {
	// 		document.body.classList.remove('has-grid-menu-open');
	// 	});
	// });
});

function removeBackgroundFilter() {
	document.body.addEventListener('click', (event) => {
		// const isMenuActive = menuToggle.classList.contains('activated');
		// console.log({
		// 	eventFooter: event.target.classList.contains('site-footer'),
		// 	eventSiteInner: event.target.classList.contains('site-inner'),
		// });
		// console.log({
		// 	target: event.target,
		// 	offsetParent: event.target.offsetParent,
		// });

		if (event.target.classList.contains('site-inner')) {
			document.body.classList.remove('primary-nav-open');
		}
		// if (!event.target.classList.contains('site-footer')) {
		// 	return false;
		// }

		// // if (menuToggle.classList.contains('activated')) {
		// const primaryNav = document.querySelector('.nav-primary');
		// const menuToggle = document.querySelector('.menu-toggle');
		//
		// // console.log({
		// // 	menuToggle: menuToggle,
		// // 	primaryNav: primaryNav,
		// // });
		// console.log({
		// 	style: menuToggle.classList.contains('activated'),
		// });
		// if (menuToggle.classList.contains('activated')) {
		// 	menuToggle.classList.remove('activated');
		// 	primaryNav.style.display = 'none';
		// }

		// }
	});
}

jQuery(function () {
	// jQuery( document.body ).trigger( 'wc_fragment_refresh' );
	removeBackgroundFilter();
	pricesStripCurrencySymbol();
});

// document.querySelectorAll('*').forEach((el) => {
// 	// console.log('element: ', el);
// 	if (el.offsetWidth > document.documentElement.offsetWidth) {
// 		console.log('OVERFLOW ----- Found the worst element ever: ', el);
// 	}
// });
