import $ from 'jquery';

export default function() {

    $('.woocommerce-Price-amount.amount bdi .woocommerce-Price-currencySymbol').remove();

    $('.woocommerce-Price-amount.amount bdi').each(function(i, price){
        let priceText = $(this).text( $.trim( $(this).text() ) );
    });

}