import throttle from '../../helpers/throttle';

const onScroll = ((window, $) => {
	const init = () => {
		const setCSSVar = (siteHeader) => {
			const root = document.documentElement;
			// mq = window.matchMedia('(min-width: 1024px)');
			// const siteInner = $('.site-inner')
			const siteHeaderHeight = siteHeader.outerHeight(true);
			// console.log('siteHeaderHeight', siteHeaderHeight);
			// if (document.body.classList.contains('single-product')) {
			// 	siteHeaderHeight = $('.site-header .wrap').outerHeight(true);
			// }
			// console.log('mq.matches', mq.matches);
			// if (mq.matches) {
			root.style.setProperty(
				'--site-inner-margin-top',
				`${siteHeaderHeight}px`
			);
			// }
		};
		const $window = $(window),
			siteHeader = $('.site-header');

		setCSSVar(siteHeader);

		const updateThrottleResize = throttle(() => {
			$window.on('scroll', function () {
				const bodyScroll = $window.scrollTop();
				setCSSVar(siteHeader);
				if (bodyScroll > 145) {
					siteHeader.addClass('site-header-with-scroll');
				} else {
					siteHeader.removeClass('site-header-with-scroll');
				}
			});

			$window.on('resize', function () {
				setCSSVar(siteHeader);
			});
		}, 500);

		updateThrottleResize();
	};

	return {
		init,
	};
})(window, jQuery);

export default onScroll;
