const primaryMenu = ((window) => {
	const init = () => {
		const primaryMenuItems = document.querySelectorAll(
			'.menu-primary > li.menu-item-has-children'
		);

		document.body.addEventListener('click', (event) => {
			const isPrimaryMenu = event.target.closest('.menu-primary');
			// const menuToggle = event.target.closest('.menu-toggle');
			if (!isPrimaryMenu) {
				_removeActiveMenuItem(primaryMenuItems);
				// document.body.classList.remove('nav-open', 'primary-nav-open');
			}
		});

		primaryMenuItems.forEach((el) => {
			el.addEventListener('click', () => {
				_activateMenuItem(el);
			});
		});
	};

	const _activateMenuItem = (el) => {
		const body = document.getElementsByTagName('BODY')[0];
		const theOtherMenuItems = [
			...document.querySelectorAll('.menu-item-open'),
		].filter((item) => {
			return item !== el;
		});
		_removeActiveMenuItem(theOtherMenuItems);
		if (!el.classList.contains('menu-item-open')) {
			el.classList.add('has-grid-menu-open');
			el.classList.add('menu-item-open');
			body.classList.add('primary-nav-open');
		} else {
			el.classList.remove('has-grid-menu-open');
			el.classList.remove('menu-item-open');
			body.classList.remove('primary-nav-open');
		}
	};

	const _removeActiveMenuItem = (elements) => {
		document.body.classList.remove('primary-nav-open');
		elements.forEach((el) => {
			el.classList.remove('has-grid-menu-open');
			el.classList.remove('menu-item-open');
		});
	};

	return {
		init,
	};
})(window);

export default primaryMenu;
